/**
 * NOTE:
 * this values are set from the window object that it is injected in the main.js overrideClientSideServiceAddresses()
 * method of by the running server.
 * They only revert to default local settings if the window params are not found but it should not happen!
 *
 * NOTE: only the ENV value is not set in the window object
 *
 */
export const version = (function(_serverUrl){
  if(_serverUrl){
    return _serverUrl;
  }
  else{
    return "";
  }
}(window['version'])) ;

export const serverUrl = (function(_serverUrl){
  if(_serverUrl){
    return _serverUrl;
  }
  else{
    return "https://pomup-macbook.home:443";
  }
}(window['serverUrl'])) ;

export const remoteCouchDbUrl = (function(_remoteCouchDbUrl){
  if(_remoteCouchDbUrl){
    return _remoteCouchDbUrl;
  }
  else{
    return "https://pomup-macbook.home:6984/";
  }
}(window['remoteCouchDbUrl'])) ;

export const ENV = (function(_ENV){
  if(_ENV){
    return _ENV;
  }
  else{
    return "local";
  }
}(window['env'])) ;


