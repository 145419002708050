import { version, serverUrl, remoteCouchDbUrl, ENV } from './config';
import XConsole from "../shared/libs/XConsole";


let muteLog = false;
let muteInfo = false;
let muteWarnings = false;
let muteDebug = false;

if(ENV === 'local'){
  muteLog = true;
  muteInfo = true;
  muteWarnings = false;
  muteDebug = false;
}

if(ENV === "development"){
  muteLog = true;
  muteInfo = true;
  muteWarnings = false;
  muteDebug = false;
}

if(ENV === "production"){
  muteLog = true;
  muteWarnings = true;
  muteInfo = true;
  muteDebug = true;
}

if(muteLog){
  window.console.log = ()=>{};
}
if(muteWarnings){
  window.console.warn = ()=>{};
}
if(muteInfo){
  window.console.info = ()=>{};
}
if(muteDebug){
  window.console.debug = ()=>{};
}

XConsole.info("[globals.ts]", version, serverUrl, remoteCouchDbUrl, ENV);

export let Globals = {
  env:ENV,
  appVersion: version,
  serverUrl:serverUrl,
  remoteCouchDbUrl:remoteCouchDbUrl
};

